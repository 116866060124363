import { getCurrentEnv, ENDPOINTS } from '../API';

export const redirectToPilot = (path, endpoint) => {
  let redirectTo = window.location.href.replace(window.location.host, endpoint || ENDPOINTS.pilot.app);
  if (path) {
    redirectTo = `https://${endpoint || ENDPOINTS.pilot.app}${path}`;
  }
  if (redirectTo) {
    window.location.href = redirectTo;
  }
};

export const redirectToPrebookPreview = (aPrebook) => {
  const environmentPrefix = getCurrentEnv() === 'production' ? 'admin' : `admin-${getCurrentEnv()}`;
  window.open(`https://${environmentPrefix}.tulka.com/admin/prebooking/${aPrebook.id}`, '_blank').focus();
};

export const getViewState = (error) => {
  let stateName = null;
  if (typeof error === 'string') {
    if (error.includes('Contact info not unique')) {
      if (error.includes('Contact info not unique type: Interpreter')) {
        stateName = 'notUniquePhone';
      } else { 
        stateName = 'notUnicEmail';
      }
    }

    if (error.includes('Invalid domain')) {
      stateName = 'invalidDomain';
    }
  }
  return stateName;
};

export const SELFREGISTRATION_ROUTES = {
  email: '/rekisteroidy',
  check_email: '/rekisteroidy/vahvista-sahkoposti',
  phone: '/rekisteroidy/puhelinnumero',
  check_phone: '/rekisteroidy/vahvista-puhelinnumero',
  cost_center: '/rekisteroidy/kustannuspaikka',
  password: '/rekisteroidy/salasana',
  auth_complete: '/rekisteroidy/auth-complete',
  verification: '/rekisterointi-valmis',
};

export const REDIRECT_DELAY = 1000;