import React from 'react';
import styled from 'styled-components';

import {
  StepsHeader,
  FlexItem,
} from './basicComponents';
import { HeaderFeedbackStep } from './HeaderFeedbackStep';
import Spinner from '../components/Spinner';

const StyledStepsHeader = styled(StepsHeader)`
  color: ${({ isCover }) => isCover ? 'white' : '#46446F'};
  background: ${({ isCover }) => isCover ? '46446F' : 'transparent'};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-flow: column;
  text-align: center;
  height: calc(100vh - 116px);
  background: rgb(246,249,252);
  background: linear-gradient(180deg, rgba(70,68,111,1) 8%, rgba(246,249,252,1) 8%);
`;

const TextContainer = styled.div`
  border-radius: 8px;
  padding: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 16px;
  width: 80%;
  margin: 0 auto;
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
  background-color: white;
  flex-flow: column;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.centered {
    justify-content: center;
  }
`;

const TextMessage = ({ text }) => {
  return (
    <TextContainer>
      <StyledRow className='centered'>
        <p>{text}</p>
      </StyledRow>
    </TextContainer>
  );
};

const ThirdFeedbackStep = (props) => {
  const { content, header, indicator, isMobileWidth } = props;

  return (
   <FlexItem>
      <HeaderFeedbackStep
        header={header}
        showPrev={isMobileWidth}
        showContinue={false}
      />
      <ContentContainer>
        {indicator ? (<Spinner overlay />): null}
        <StyledStepsHeader isCover={true}>{content.title}</StyledStepsHeader>
        <TextMessage 
          text={content.message}
        />
      </ContentContainer>
   </FlexItem>
  );
};

export default ThirdFeedbackStep;
