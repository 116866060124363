export const ENDPOINTS = {
  production: {
    http: 'https://api.tulka.com',
    ws: 'wss://ws.tulka.com',
    app: 'app.tulka.com',
    platform: 'https://prod-api-v2.tulka.com/',
    integration: 'https://integrations2.tulka.com'
  },
  release: {
    http: 'https://release-api-v2.tulka.com/',
    ws: 'wss://release-ws.tulka.com',
    app: 'release-app.tulka.com',
    platform: 'https://release-api-v2.tulka.com/',
    integration: 'https://integrations.release.tulka.com'
  },
  pilot: {
    http: 'https://api.tulka.com',
    ws: 'wss://ws.tulka.com',
    app: 'newapp.tulka.com',
    platform: 'https://prod-api-v2.tulka.com/',
    integration: 'https://integrations2.tulka.com'
  },
  development: {
    http: 'https://preprod.tulka.com',
    ws: 'wss://wspreprod.tulka.com',
    app: 'preprod-app.tulka.com',
    platform: 'https://preprod-api-v2.tulka.com/',
    integration: 'https://integrations.preprod.tulka.com'
  }
};

const env = process.env.REACT_APP_ENV || 'development';
const ENDPOINT = ENDPOINTS[env];

export const getCurrentEnv = () => {
  if (window.location.host === ENDPOINTS.production.app) {
    return 'production';
  }
  if (window.location.host === ENDPOINTS.pilot.app) {
    return 'pilot';
  }
  if (window.location.host === ENDPOINTS.release.app) {
    return 'release';
  }
  return 'development';
};

export const URL = ENDPOINT.http;

export const WSURL = ENDPOINT.ws;

export const VERSION = '/api/v1';

export const PLATFORM = ENDPOINT.platform;

export const INTEGRATION = ENDPOINT.integration;
