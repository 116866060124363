import React, { useContext } from 'react';
import ThemeContext from '../contexts/ThemeContext';

import styled from 'styled-components';

import {
  StepsHeader,
  FlexItem,
} from './basicComponents';
import { HeaderFeedbackStep, FooterFeedbackStep } from './HeaderFeedbackStep';
import Spinner from '../components/Spinner';

import i18n from '../i18n';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

const StyledStepsHeader = styled(StepsHeader)`
  color: ${({ isCover }) => isCover ? 'white' : '#46446F'};
  background: ${({ isCover }) => isCover ? '46446F' : 'transparent'};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-flow: column;
  text-align: center;
  height: calc(100vh - 116px);
  background: rgb(246,249,252);
  background: linear-gradient(180deg, rgba(70,68,111,1) 8%, rgba(246,249,252,1) 8%);
`;

const TextContainer = styled.div`
  border-radius: 8px;
  padding: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 16px;
  width: 80%;
  margin: 0 auto;
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
  background-color: white;
  flex-flow: column;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTextarea = styled.textarea`
  padding: 1rem;
  background-color: #f6f9fc;
  border: 1px solid rgb(216, 216, 220);
  resize: none;
  ::placeholder {
    color: rgb(216, 216, 220);
  }
`;

const OpenFeedback = ({ contactWanted, handleContactChange, openFeedback, handleOpenFeedbackChange }) => {
  const { colors } = useContext(ThemeContext);

  const PurpleSwitch = withStyles({
    switchBase: {
      color: '#ccc',
      '&$checked': {
        color: colors.feedbackSwitchColor
      },
      '&$checked + $track': {
        backgroundColor: colors.feedbackSwitchColor
      },
    },
    checked: {},
    track: {
      backgroundColor: '#ccc'
    },
  })(Switch);

  return (
    <TextContainer>
      <StyledTextarea
        rows={6}
        cols={50}
        placeholder={i18n('describe_your_experience_here')}
        value={openFeedback}
        onChange={(e) => handleOpenFeedbackChange(e.target.value)}
      />
      <StyledRow>
        <p>{i18n('i_wish_to_be_contacted')}</p>
        <PurpleSwitch
          checked={contactWanted}
          onChange={handleContactChange}
        />
      </StyledRow>
    </TextContainer>
  );
};

const SecondFeedbackStep = (props) => {
  const { header, nextStep, previousStep, skipStep, contactWanted, handleContactChange, openFeedback, handleOpenFeedbackChange, handleSubmit, indicator, isMobileWidth, isShowThirdStep } = props;

  const handleNext = () => {
    handleSubmit();
    if (isShowThirdStep) {
      nextStep();
    }
  };

  return (
   <FlexItem>
      <HeaderFeedbackStep
        header={header}
        showPrev={isMobileWidth}
        previousStep={previousStep}
        nextStep={nextStep}
        skipStep={skipStep}
        showContinue={false}
        nextButtonLabel={i18n('skip')}
      />
      <ContentContainer>
        {indicator ? (<Spinner overlay />): null}
        <StyledStepsHeader isCover={true}>{i18n('leave_open_feedback')}</StyledStepsHeader>
        <OpenFeedback 
          contactWanted={contactWanted}
          handleContactChange={handleContactChange} 
          openFeedback={openFeedback}
          handleOpenFeedbackChange={handleOpenFeedbackChange}
        />
        <FooterFeedbackStep 
          header={header}
          showPrev={!isMobileWidth}
          showNext={true}
          nextStep={handleNext}
          previousStep={previousStep}
          nextButtonLabel={i18n('proceed')}
        />
      </ContentContainer>
   </FlexItem>
  );
};

export default SecondFeedbackStep;
