import { isNeedChooseInterpreterStep } from './luonaStepsBuilder';
import { getData } from './localStorage';

export const DATA = {
  production: {
    servises: {
      leadTeameServiseId: '2bc6b9ed-c194-4126-8467-220c90073a8c',
      medicalServiseId: '9fb23ece-66d5-4a41-b13f-2deac7270d07',
      socialServiseId: '75c1f451-4ebd-4ccc-90e3-215672bcbd09',
      preferentialServiseId: 'f9f4efa0-1006-4b2a-bede-1700e5c4e5c3'
    },
    targetLanguageId: 'dc851e78-590c-4876-8882-3e36f1366eb0',
    langsIdsNotNeedInterpretations: ['2df1fa27-998a-4b32-989c-fc4bb6afc92c', 'dc851e78-590c-4876-8882-3e36f1366eb0'],
    schedule: {

    }
  },
  release: {
    servises: {
      leadTeameServiseId: '45e4a339-03d0-4c24-a632-9642a1d65ea0',
      medicalServiseId: 'db29b3cb-717c-4d8d-a9aa-e453abc3caa1',
      socialServiseId: '89364799-5786-44ad-a258-8ad1af6726f0',
      preferentialServiseId: '36a39eb0-dfcf-4179-b4b0-4ce2a9eae6f2'
    },
    targetLanguageId: 'dc851e78-590c-4876-8882-3e36f1366eb0',
    langsIdsNotNeedInterpretations: ['2df1fa27-998a-4b32-989c-fc4bb6afc92c', 'dc851e78-590c-4876-8882-3e36f1366eb0']
  },
  development: {
    servises: {
      leadTeameServiseId: '45e4a339-03d0-4c24-a632-9642a1d65ea0',
      medicalServiseId: 'db29b3cb-717c-4d8d-a9aa-e453abc3caa1',
      socialServiseId: '89364799-5786-44ad-a258-8ad1af6726f0',
      preferentialServiseId: '36a39eb0-dfcf-4179-b4b0-4ce2a9eae6f2'
    },
    targetLanguageId: 'dc851e78-590c-4876-8882-3e36f1366eb0',
    langsIdsNotNeedInterpretations: ['2df1fa27-998a-4b32-989c-fc4bb6afc92c', 'dc851e78-590c-4876-8882-3e36f1366eb0']
  }
};

const env = process.env.REACT_APP_ENV || 'development';
const CURRENT_DATA = DATA[env];

export const onlyLuonaCustomerPathes = ['/wizard', '/prebooking_luona', '/signup_luona'];

export const isLuonaHost = () => {
  return window.location.host.split('.')[1] === 'paradigmasoft';
};

export const isLuonaCustomer = () => {
  const user = getData('user');
  return isLuonaHost() && user?.type !== 'Interpreter';
};

export const SERVISES = CURRENT_DATA.servises;
export const TARGET_LANGUAGE_ID = CURRENT_DATA.targetLanguageId;
export const LANGS_IDS_NOT_NEED_INTERPRETATIONS = CURRENT_DATA.langsIdsNotNeedInterpretations;
export const INTERPRETED_LANGUAGE_TAGS = ['default', 'lna'];
export const SCHEDULE = {
  [SERVISES.leadTeameServiseId]: '09.00 - 14.00',
  [SERVISES.medicalServiseId]: '09.00 - 14.00',
  [SERVISES.socialServiseId]: '09.00 - 11.30',
  [SERVISES.preferentialServiseId]: '09.00 - 11.30',
};

export const isInterpretationPrebookNeeded = languageId => {
  const langsIdsNotNeedInterpretations = LANGS_IDS_NOT_NEED_INTERPRETATIONS;
  return !langsIdsNotNeedInterpretations.includes(languageId);
};

export const getIsNeedInterpreter = (locale) => {
  try {
    const value = localStorage.getItem('isNeedChooseInterpreterStep');
    if (typeof value === 'string') {
      return JSON.parse(value);
    }
    return isNeedChooseInterpreterStep(locale);
  } catch (error) {
    console.error('Failed to parse isNeedChooseInterpreterStep', error);
    return isNeedChooseInterpreterStep(locale);
  }
};

export const initAvaliableService = [
  {
    value: 'health',
    label: 'health',
    skill: SERVISES.medicalServiseId,
  },
  {
    value: 'work_or_study',
    label: 'work_or_study',
    skill: SERVISES.socialServiseId,
  },
  {
    value: 'money_or_livelihood',
    label: 'money_or_livelihood',
    children: [
      {
        value: 'reception_allowance',
        label: 'reception_allowance',
        skill: SERVISES.preferentialServiseId,
      },
      {
        value: 'my_money_is_not_enough',
        label: 'my_money_is_not_enough',
        skill: SERVISES.socialServiseId,
      },
    ],
  },
  {
    value: 'related_to_your_own_or_family_life_situation',
    label: 'related_to_your_own_or_family_life_situation',
    skill: SERVISES.socialServiseId,
  },
  {
    value: 'legal_matter',
    label: 'legal_matter',
    skill: SERVISES.socialServiseId,
  },
  {
    value: 'other_practical_matters',
    label: 'other_practical_matters',
    children: [
      {
        value: 'https://migri.fi',
        label: 'emigration_or_holiday_outside_Finland',
        type: 'link',
        localeKey: 'emigration_or_holiday_outside_Finland'
      },
      {
        value: 'https://www.infofinland.fi/fi/leisure',
        label: 'free_time',
        type: 'link',
        localeKey: 'free_time'
      },
    ],
  },
  {
    value: 'housing',
    label: 'housing',
    skill: SERVISES.leadTeameServiseId,
  },
  {
    value: 'child_care',
    label: 'child_care',
    children: [
      {
        value: 'kindergarten_or_school',
        label: 'kindergarten_or_school',
        skill: SERVISES.socialServiseId,
      },
      {
        value: 'child_health',
        label: 'child_health',
        skill: SERVISES.medicalServiseId,
      },
      {
        value: 'upbringing_parenting',
        label: 'upbringing_parenting',
        skill: SERVISES.socialServiseId,
      },
    ],
  },
  {
    value: 'reception_centre_info_service',
    label: 'reception_centre_info_service',
    skill: SERVISES.leadTeameServiseId,
  }
];