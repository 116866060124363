import styled from 'styled-components';
import { useState, useCallback, useContext } from 'react';
import ThemeContext from '../contexts/ThemeContext';
import { StepsHeader } from './basicComponents';
import { HeaderFeedbackStep, FooterFeedbackStep } from './HeaderFeedbackStep';
import i18n from '../i18n';
import icons from '../images/icons.svg';
import Spinner from '../components/Spinner';

const StyledStepsHeader = styled(StepsHeader)`
  color: ${({ isCover }) => isCover ? 'white' : '#46446F'};
  background: ${({ isCover }) => isCover ? '46446F' : 'transparent'};
`;

const StyledStepsSubHeader = styled(StepsHeader)`
  color: ${({ isCover }) => isCover ? 'white' : '#46446F'};
  background: ${({ isCover }) => isCover ? '46446F' : 'transparent'};
  padding-bottom: 2rem;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-flow: column;
  text-align: center;
  height: calc(100vh - 116px);
  background: rgb(246,249,252);
  background: linear-gradient(180deg, rgba(70,68,111,1) 8%, rgba(246,249,252,1) 8%);
`;

const StarRatingContainer = styled.div`
  padding: 1rem;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 16px;
  margin: 0 auto;
  margin-top: 1rem;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  background-color: white;
  width: 80%;
`;

const StarsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  background-color: white;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const ImprovementsContainer = styled.div`
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  background-color: white;
  flex-flow: column;
  margin-bottom: 1rem;
`;

const ImprovementsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
`;

const ImprovementsItem = styled.li`
  cursor: pointer;
  text-align: left;
  padding: 1rem;
  background-color: ${({isSelected}) => isSelected ? '#ccc' : 'white'};
  :hover {
    background-color: #ccc;
  }
  text-align: center;
`;

const Star = styled.span`
  cursor: pointer;
  padding: .1rem .5rem;
`;

const Icon = ({ score, name = '', className = 'icon', color = '#ffffff', stroke = '', width = '2rem', height = '2rem' }) => {
  return (
    <svg
      data-score={score}
      className={`icon ${className}`}
      fill={color}
      stroke={stroke}
      width={width}
      height={height}
    >
      <use href={`${icons}#${name}`} data-score={score}></use>
    </svg>
  );
};

const StarRating = ({ totalStars = 5, setRating, rating }) => {
  const { colors } = useContext(ThemeContext);
  const [hoveredStars, setHoveredStars] = useState(0);

  const handleHover = useCallback((e) => {
    if (e?.target?.dataset?.score) {
      setHoveredStars(parseInt(e.target.dataset.score));
    } else {
      setHoveredStars(0);
    }
  }, [setHoveredStars]);

  const handleClick = useCallback((e) => {
    if (e?.target?.dataset?.score) {
      setRating(e.target.dataset.score);
    } 
  }, [setRating]);

  const coloredStars = rating ? rating : hoveredStars;

  return (
    <StarsContainer hoveredStars={coloredStars} onMouseOver={handleHover} onClick={handleClick}>
      {[...Array(totalStars)].map((_, index) => (
        <Star data-score={index + 1} key={index}>
          <Icon 
            score={index + 1} 
            name='star' 
            color={coloredStars >= index + 1 ? colors.feedbackBgColor : '#FFF'} 
            stroke={colors.feedbackBgColor} 
            className='star-icon' 
            width='32px' 
            height='31px'
          />
        </Star>
      ))}
    </StarsContainer>
  );
};

const SelectImprovements = ({ selectedImprovements, availableImprovements, handleSwitchImprovement }) => {

  const handleClick = useCallback((improvement) => {
    handleSwitchImprovement(improvement);
  }, [handleSwitchImprovement]);

  return (
    <>
      <StyledStepsHeader>{i18n('where_can_we_improve_upon')}</StyledStepsHeader>
      <StyledStepsSubHeader>{i18n('choose_one_or_more_options')}</StyledStepsSubHeader>
      <ImprovementsContainer>
        <ImprovementsList>
          {availableImprovements?.map((improvement) => {
            return (
              <ImprovementsItem 
                key={improvement.key} 
                isSelected={selectedImprovements.find(i => i.key === improvement.key)}
                onClick={() => handleClick(improvement)}
              >{improvement.value}</ImprovementsItem>
            );
          })}
        </ImprovementsList>
      </ImprovementsContainer>
    </>
  );
};

const FirstFeedbackStep = (props) => {
  const { header, nextStep, previousStep, skipStep, stars, improvements, selectedImprovements, handleImprovementSelection, handleRatingChange, indicator, isImprovementsRequired } = props;
  const { colors } = useContext(ThemeContext);

  return (
    <>
      <HeaderFeedbackStep
        header={header}
        nextStep={nextStep}
        skipStep={skipStep}
        showContinue={true}
        nextButtonLabel={i18n('skip')}
      />
      <ContentContainer>
        {indicator ? (<Spinner overlay />): null}
        <StyledStepsHeader isCover={true}>{i18n('how_did_we_do')}</StyledStepsHeader>
        <StarRatingContainer>
          <StyledStepsHeader>{i18n('stars_rating_subtitle')}</StyledStepsHeader>
          <StarRating
            setRating={(star) => handleRatingChange('stars', star)}
            rating={stars}
            className='StarRating'
          />
          {stars && stars < 5 ? (
            <SelectImprovements
              activeColor={colors.tulkaMainColor}
              hoverColor={colors.tulkaMainColor}
              backgroundHoverColor = '#DFFFFE'
              selectedImprovements={selectedImprovements}
              availableImprovements={improvements}
              handleSwitchImprovement={handleImprovementSelection}
            />
          ) : null }
        </StarRatingContainer>
        <FooterFeedbackStep 
          header={header}
          showPrev={false}
          showNext={true}
          nextStep={nextStep}
          previousStep={previousStep}
          nextButtonLabel={i18n('continue')}
          isDisabledNext={isImprovementsRequired}
        />
      </ContentContainer>
    </>
  );
};

export default FirstFeedbackStep;
